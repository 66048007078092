<template>
  <div
    class="profilphoto"
    align="center"
  >
    <input
      id="uploadPhoto"
      ref="file"
      type="file"
      accept="image/*"
      style="display: none"
      @input="choosePhoto($event)"
    />
    <v-menu
      :disabled="readonly"
      absolute
    >
      <template #activator="{ props }">
        <v-avatar
          :size="size"
          :class="{ 'avatar-disabled': readonly, 'cursor-pointer': true }"
          color="blue-grey-lighten-4"
          v-bind="props"
        >
          <v-img
            v-if="fileId"
            ref="profilePhoto"
            :src="imageUrl"
          >
            <template #placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey-lighten-4"
                />
              </v-row>
            </template>
          </v-img>
          <v-icon
            v-else-if="!firstName || !lastName"
            :size="size"
          >
            mdi-account-circle
          </v-icon>
          <p
            v-else
            :style="{ fontSize: size / 3 + 'px' }"
          >
            {{ firstName.charAt(0) }}{{ lastName.charAt(0) }}
          </p>
        </v-avatar>
      </template>
      <v-list density="compact">
        <v-list-item
          data-cy="uploadPhoto"
          prepend-icon="mdi-upload"
          @click="!readonly && userId && $refs.file.click()"
        >
          <v-list-item-title>Neues Foto hochladen</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="fileId"
          prepend-icon="mdi-delete"
          @click="deletePhoto()"
        >
          <v-list-item-title>Foto löschen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="cropImage"
      persistent
      max-width="800"
    >
      <v-card class="px-2 pb-5 pt-0">
        <template #append>
          <v-btn
            icon="$close"
            variant="text"
            data-cy="closeHelpDialog"
            @click="abortUpload"
          >
          </v-btn>
        </template>
        <v-card-item class="pt-0">
          <v-row>
            <v-col>
              <v-card-text class="text-h3 text-center pt-0 mt-0"> Foto zuschneiden </v-card-text>
            </v-col>
          </v-row>
        </v-card-item>
        <v-card-text>
          <cropper
            ref="cropper"
            class="upload-example-cropper"
            :src="image"
            :stencil-component="$options.components.CircleStencil"
          />
        </v-card-text>
        <v-card-actions>
          <!--v-btn
            data-cy="abortUpload"
            color="warning-darken-1"
            variant="outlined"
            rounded="xl"
            class="px-10"
            @click="abortUpload"
          >
            Abbrechen
          </v-btn -->
          <v-btn
            data-cy="uploadPhoto"
            color="primary-darken"
            class="px-10 mx-auto"
            variant="elevated"
            rounded="xl"
            :loading="loading"
            @click="uploadPhoto()"
          >
            Foto hochladen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    CircleStencil,
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
    skipLoading: {
      type: Boolean,
    },
    size: {
      type: [String, Number],
      default: 120,
    },
    readonly: {
      type: Boolean,
    },
  },
  data: () => ({
    file: null,
    fileId: '',
    imageUrl: null,
    image: null,
    cropImage: false,
    loading: false,
    firstName: '',
    lastName: '',
  }),
  async mounted() {
    if (this.userId && !this.skipLoading) {
      await this.$cms
        .request(
          this.$readUser(this.userId, { fields: ['id', 'first_name', 'last_name', 'avatar'] })
        )
        .then((user) => {
          this.firstName = user.first_name
          this.lastName = user.last_name
          if (user.avatar) {
            this.fileId = user.avatar
            this.fileUrl(this.fileId).then((fileUrl) => {
              this.imageUrl = `${fileUrl}&fit=cover&width=${this.size}&height=${this.size}&quality=80`
            })
          }
        })
    }
  },
  methods: {
    choosePhoto(event) {
      const input = event.target
      if (input.files && input.files.length) {
        this.file = input.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
          this.image = e.target.result
          this.cropImage = true
        }
        reader.readAsDataURL(this.file)
      }
    },
    async uploadPhoto() {
      await this.deletePhoto()
      this.loading = true
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      const jpg = this.convertToJpgFile(canvas, 800, 0.7)
      const profilePicture = await this.$uploadFile(jpg, jpg.name, 'profile_pictures', [
        'profile_picture',
        this.userId,
      ])

      this.fileId = profilePicture.id

      await this.$cms.request(this.$updateUser(this.userId, { avatar: this.fileId }))

      this.loading = false
      this.cropImage = false
      this.$refs.file.value = null
      const fileUrl = await this.fileUrl(this.fileId)
      this.imageUrl = `${fileUrl}&fit=cover&width=${this.size}&height=${this.size}&quality=80`
      this.$notificationStore.set({
        text: 'Profilbild erfolgreich hochgeladen.',
        type: 'success',
      })
    },
    async deletePhoto() {
      if (this.fileId) {
        this.loading = true
        await this.$cms.request(this.$updateUser(this.userId, { avatar: null }))
        await this.$cms.request(this.$deleteFile(this.fileId))
        this.fileId = null
        this.loading = false
      }
    },
    convertToJpgFile(src, width, quality) {
      const resizedCanvas = document.createElement('canvas')
      const resizedContext = resizedCanvas.getContext('2d')
      const height = (src.height * width) / src.width
      resizedCanvas.width = width
      resizedCanvas.height = height
      resizedContext.drawImage(src, 0, 0, width, height)

      const image = resizedCanvas.toDataURL('image/jpeg', quality)

      const binary = atob(image.split(',')[1])
      const array = []
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }

      return new File([new Uint8Array(array)], 'profile_picture.jpg', {
        type: 'image/jpeg',
      })
    },
    abortUpload() {
      this.cropImage = false
      this.$refs.file.value = null
    },
  },
}
</script>

<style scoped lang="scss">
.avatar-disabled {
  cursor: default !important;
}
</style>
